// Dependencies
import React, { useState, useRef } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { post } from "@api/apiInstance";
import {
	Recaptcha,
	Button,
	InputTextForm,
	InputCheckbox,
	Card,
} from "@components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { LuSend } from "react-icons/lu";

export default function ContactForm() {
	const recaptchaRef = useRef();
	const [fetching, setFetching] = useState(false);
	const intl = useIntl();
	const schema = yup.object({
		full_name: yup
			.string()
			.required(intl.formatMessage({ id: "validation_required" })),
		email: yup
			.string()
			.email(intl.formatMessage({ id: "validation_email" }))
			.required(intl.formatMessage({ id: "validation_required" })),
		phone: yup
			.string()
			.required(intl.formatMessage({ id: "validation_required" })),
		accept_privacy: yup
			.bool()
			.required(intl.formatMessage({ id: "validation_conditions" }))
			.oneOf([true], intl.formatMessage({ id: "validation_conditions" })),
	});

	const acceptPrivacityLabel = intl.formatMessage(
		{ id: "misc_accept_privacy" },
		{
			misc_privacy: (
				<Link to="/politica-privacidad/">
					{intl.formatMessage({ id: "misc_privacy" })}
				</Link>
			),
			misc_legal: (
				<Link to="/politica-legal/">
					{intl.formatMessage({ id: "misc_legal" })}
				</Link>
			),
		},
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(schema),
		initialValues: {
			full_name: "",
			email: "",
			phone: "",
			accept_privacy: false,
			accept_newsletter: true,
		},
	});

	async function onSubmit(values) {
		setFetching(true);
		try {
			const recaptchaToken = await recaptchaRef.current.executeAsync();
			await post("/contact", { ...values, recaptchaToken });
			toast("✅ Contacto enviado correctamamente", {
				className: "text-sm",
			});
			reset();
		} catch (err) {
			console.error(err);
			toast("❌ Se produjo un error, vuelva a intentarlo", {
				className: "text-sm",
			});
		} finally {
			setFetching(false);
		}
	}

	return (
		<Card className="mt-[1rem]">
			<Card.Header>
				<b>
					<FormattedMessage id="misc_contact.title" />
				</b>
			</Card.Header>
			<Card.Body>
				<form
					className="grid grid-cols-1 gap-4"
					noValidate
					onSubmit={handleSubmit(onSubmit)}
				>
					<InputTextForm
						isRequired
						id="full_name"
						label={intl.formatMessage({
							id: "misc_full_name",
						})}
						placeholder={intl.formatMessage({
							id: "misc_full_name",
						})}
						errors={errors}
						{...register("full_name")}
					/>
					<InputTextForm
						isRequired
						type="email"
						id="email"
						label={intl.formatMessage({
							id: "misc_email",
						})}
						placeholder={intl.formatMessage({
							id: "misc_email",
						})}
						errors={errors}
						{...register("email")}
					/>
					<InputTextForm
						isRequired
						type="tel"
						id="phone"
						label={intl.formatMessage({
							id: "misc_phone",
						})}
						placeholder={intl.formatMessage({
							id: "misc_phone",
						})}
						errors={errors}
						{...register("phone")}
					/>

					<InputCheckbox
						{...register("accept_privacy")}
						id="accept_privacy"
						label={acceptPrivacityLabel}
						errors={errors}
					/>
					<InputCheckbox
						defaultChecked={true}
						{...register("accept_newsletter")}
						id="accept_newsletter"
						label={intl.formatMessage({
							id: "misc_accept_newsletter",
						})}
						errors={errors}
					/>

					<Recaptcha ref={recaptchaRef} />

					<Button color="trucksur" type="submit" isLoading={fetching}>
						<div className="flex w-fit mx-auto">
							<LuSend className="h-5 w-5" />
							<div className="ml-[5px]">
								<FormattedMessage id="misc_send" />
							</div>
						</div>
					</Button>
				</form>
			</Card.Body>
		</Card>
	);
}
