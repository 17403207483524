// Dependencies
import React, { useState } from "react";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import classNames from "classnames";

export function NextArrow({ className, onClick }) {
	return (
		<button
			aria-label="Siguiente"
			type="button"
			className={classNames(
				className,
				"flex items-center justify-center h-full w-10 absolute right-3 z-[5] before:content-['']",
			)}
			onClick={onClick}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth="3"
				stroke="currentColor"
				className="w-6 h-6 stroke-white"
			>
				<title>Siguiente</title>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
				/>
			</svg>
		</button>
	);
}

export function PrevArrow({ className, onClick }) {
	return (
		<button
			aria-label="Anterior"
			type="button"
			className={classNames(
				className,
				"flex items-center justify-center h-full w-10 absolute left-3 z-[5] before:content-['']",
			)}
			onClick={onClick}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth="3"
				stroke="currentColor"
				className="w-6 h-6 stroke-white"
			>
				<title>Anterior</title>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
				/>
			</svg>
		</button>
	);
}

export default function ImagesVehicleCarousel({
	images,
	slickProps,
	handleClick,
	initialIndexSlide = 0,
	isBankable,
	...props
}) {
	const [actualSlide, setActualSlide] = useState(initialIndexSlide);
	const sliderProps = {
		...slickProps,
		initialSlide: initialIndexSlide,
		afterChange: setActualSlide,
	};

	return (
		<div className="relative">
			<Slider {...sliderProps}>
				{images.map((img, index) => {
					return (
						<div
							className={classNames("cursor-default", props.className)}
							key={img.order.toString()}
							onClick={() => handleClick?.({ img, index })}
							onKeyDown={() => {}}
							role="button"
							tabIndex="0"
						>
							<GatsbyImage
								alt={img.alt}
								className="w-[100%] h-[100%] object-cover"
								image={img.image.localFile.childImageSharp.gatsbyImageData}
							/>
						</div>
					);
				})}
			</Slider>
			<div
				className={classNames(
					"absolute left-2 bg-[#fff] bg-opacity-90 rounded-full px-2 text-center text-sm z-[30]",
					isBankable ? "bottom-10" : "bottom-3",
				)}
			>
				{`${actualSlide + 1} / ${images.length}`}
			</div>
		</div>
	);
}
