// Dependencies
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@components";

export default function ModalTypeDatasheet({
	visible,
	handleClose,
	data,
	downloading,
	handleDownload,
}) {
	const [type, setType] = useState("pvp");

	return (
		<Modal show={visible} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Descarga de ficha</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					El vehículo que ha seleccionado es financiable, por favor seleccione
					que tipo de precio quiere que aparezca en la ficha de cada vehículo:
				</p>

				<hr className="my-3" />
				<div className="grid grid-cols-1 gap-3">
					<div className="flex flex-col gap-1 border border-gray-600 rounded-md py-1 px-2">
						<label className="flex items-center gap-2 font-medium text-gray-900 dark:text-gray-300 mb-0">
							<input
								aria-describedby="helper-pvp"
								type="radio"
								checked={type === "pvp"}
								onChange={() => setType("pvp")}
								className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
							/>
							Precio PVP
						</label>
						<span className="text-xs text-gray-500">Precio total PVP</span>
					</div>
					<div className="flex flex-col gap-1 border border-gray-600 rounded-md py-1 px-2">
						<label className="flex items-center gap-2 font-medium text-gray-900 dark:text-gray-300 mb-0">
							<input
								id="financial-radio"
								aria-describedby="helper-financial"
								type="radio"
								checked={type === "financial"}
								onChange={() => setType("financial")}
								className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
							/>
							Precio Financiable
						</label>
						<span className="text-xs text-gray-500">
							Precio con financiación detallada
						</span>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					className="bg-trucksur-600 hover:bg-trucksur-600"
					onClick={() => handleDownload(data, type)}
					isLoading={downloading}
				>
					Descargar
				</Button>
				<Button
					className="bg-slate-400  hover:bg-slate-400"
					onClick={handleClose}
				>
					Cancelar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
