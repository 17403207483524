// Dependencies
import React, { useContext } from "react";
import FavoritesContext from "@context/FavoritesContext";
import classNames from "classnames";

export default function ButtonFavoriteHeart({ id, ...props }) {
	function handleAlternateFavorite(ev) {
		ev.preventDefault();
		alternateFavorite(id);
	}

	const { isFavorite, alternateFavorite } = useContext(FavoritesContext);
	return (
		<button
			className={classNames(
				"absolute bg-white flex items-center rounded-full justify-center z-[21] right-[10px] top-[10px] hover:scale-110 transition-all",
				props.className,
			)}
			type="button"
			onClick={handleAlternateFavorite}
			aria-label={
				isFavorite(id) ? "Eliminar de favoritos" : "Añadir a favoritos"
			}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				fill="none"
				className="w-full h-full"
			>
				<title>
					{isFavorite(id) ? "Eliminar de favoritos" : "Añadir a favoritos"}
				</title>
				<circle className="fill-white" cx="12" cy="12" r="12" />
				<path
					className="fill-red-500 h-full w-full"
					d="M8.16,4.75c-2.14,0-4.22,1.47-4.22,4.17,0,3,3.5,6.23,7.5,10.11,.16,.15,.36,.23,.56,.23s.41-.08,.56-.23c4.01-3.89,7.5-7.1,7.5-10.11,0-2.7-2.08-4.16-4.21-4.16-1.48,0-2.94,.7-3.84,2.06-.91-1.37-2.37-2.07-3.84-2.07Zm0,1.21c1.6,0,2.58,1.09,3.35,2.18,.11,.16,.3,.25,.49,.26,.2,0,.38-.09,.49-.25,.77-1.08,1.76-2.17,3.35-2.17,1.45,0,3.01,.93,3.01,2.96,0,2.15-2.32,4.57-6.85,8.97-4.4-4.26-6.85-6.76-6.85-8.97,0-.91,.31-1.67,.91-2.2,.54-.49,1.31-.77,2.1-.77Z"
				/>
				<path
					className={classNames(
						"h-full w-full",
						isFavorite(id) ? "fill-red-500" : "fill-transparent",
					)}
					d="M20.06,8.92c0,3-3.49,6.21-7.5,10.1-.15,.15-.36,.23-.56,.23s-.41-.08-.56-.23c-4-3.88-7.5-7.1-7.5-10.1,0-2.7,2.08-4.18,4.22-4.18,1.47,0,2.93,.7,3.84,2.07,.91-1.36,2.37-2.06,3.84-2.06,2.14,0,4.22,1.46,4.22,4.17Z"
				/>
			</svg>
		</button>
	);
}
