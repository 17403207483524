// Dependencies
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl";
import classNames from "classnames";
import categories from "@constants/categories";
import categoriesIcons from "@constants/categoriesIcons";

function CategoriesIcon({ id, count }) {
	const intl = useIntl();
	const icon = categoriesIcons[id]?.default;

	return (
		<div className="h-full flex flex-col gap-1 justify-between items-center py-3 px-3 text-center text-trucksur-700 text-md font-medium">
			<div className="flex flex-col justify-start gap-1">
				{icon ? (
					<img
						className="max-h-[30px]"
						src={icon}
						alt={intl.formatMessage({ id })}
					/>
				) : null}
				<div className="font-light">
					<FormattedMessage id={id} />
				</div>
			</div>
			{count ? <div>({count})</div> : null}
		</div>
	);
}

export default function CategoriesList({ className }) {
	const {
		allVehicleType: { edges: typesEdges },
	} = useStaticQuery(graphql`
    query {
      allVehicleType {
        edges {
          node {
            id
            count
          }
        }
      }
    }
  `);

	const types = typesEdges.map(({ node }) => node);
	const finalCategories = categories
		.filter(
			(item) => types.map((o) => o.id).includes(item.type) || item.isExternal,
		)
		.map((item) => {
			const findedTypes = types?.find((type) => type.id === item.type);
			return {
				...item,
				count: findedTypes?.count || 0,
			};
		});

	return (
		<div className={classNames("my-4 container mx-auto", className)}>
			<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-categList gap-4 ">
				{finalCategories.map((item) => {
					return item.isExternal ? (
						<a
							alt="Enlace externo"
							className="w-full hover:no-underline"
							href={item.href}
							target="_blank"
							rel="noreferrer"
							key={item.key}
						>
							<CategoriesIcon id={item.key} />
						</a>
					) : (
						<Link
							to={`/vehiculos/${item.seo}`}
							className="w-full hover:no-underline"
							key={item.key}
						>
							<CategoriesIcon id={item.key} count={item.count} />
						</Link>
					);
				})}
			</div>
			<div className="mt-1 h-1.5 bg-truckBlue" />
		</div>
	);
}
