// Dependencies
import React, { useMemo } from "react";
import { VehiclesListItem, ListVehiclesPricesDetail } from "@components";
import useMediaQuery from "@hooks/useMediaQuery";
import BANNERS from "@constants/banners";
import BannerParts from "./bannerParts";

function getBannersByVehicles(vehicles, mediaQuery) {
	const banners = [];
	if (mediaQuery) {
		const { itemsPerRow, rows } = mediaQuery;
		vehicles.forEach((_, index) => {
			const actualItem = index + 1;
			const itemsSplit = itemsPerRow * rows;
			const isToRenderBanner = actualItem % itemsSplit === 0;
			if (isToRenderBanner) {
				banners.push({
					colSpan: itemsPerRow,
					index,
					actualItem,
				});
			}
		});
	}
	return banners.map((banner, index) => {
		return {
			...banner,
			bannerComponent: BANNERS[index % 2 === 0 ? 0 : 1].component,
		};
	});
}

export default function VehiclesList({
	data,
	renderBanners = true,
	renderPriceDetails = true,
}) {
	const mediaQuery = useMediaQuery();
	const { vehicles, paginatedVehicles, proxEntradaImg, interest } = data;

	const banners = useMemo(() => {
		return getBannersByVehicles(vehicles, mediaQuery);
	}, [vehicles, mediaQuery]);

	return (
		<div>
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-5">
				{paginatedVehicles.reduce((acc, cur, index) => {
					const vehicleComponent = (
						<VehiclesListItem
							key={cur.id}
							data={cur}
							proxEntImg={proxEntradaImg}
							interest={interest}
						/>
					);
					const findedBanner = banners.find((banner) => banner.index === index);
					if (findedBanner && renderBanners) {
						const { index, colSpan, bannerComponent } = findedBanner;
						const Banner = (
							<div key={`banner_${index}`} className={`col-span-${colSpan}`}>
								{bannerComponent}
							</div>
						);
						return [...acc, vehicleComponent, Banner];
					}
					return [...acc, vehicleComponent];
				}, [])}
			</div>
			{renderBanners ? <BannerParts /> : null}
			{renderPriceDetails ? <ListVehiclesPricesDetail /> : null}
		</div>
	);
}
