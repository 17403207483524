// Dependencies
import React, { useState, useRef, useMemo, useEffect } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm, useController } from "react-hook-form";
import * as yup from "yup";
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl";
import { post } from "@api/apiInstance";
import {
	Recaptcha,
	Button,
	InputSelectForm,
	InputTextForm,
	InputMultiselectForm,
	InputCheckbox,
	Card,
} from "@components";
import toast from "react-hot-toast";
import categories from "@constants/categories";
import { Element } from "react-scroll";
import { getCountriesByLanguage } from "@lib/utils";
import { graphql, useStaticQuery } from "gatsby";
import { LuSend } from "react-icons/lu";
import { yupResolver } from "@hookform/resolvers/yup";
import { TYPE_REQUEST_OPTIONS } from "@constants/request_info";

function TypeRequestRadio({ control, errors, name }) {
	const intl = useIntl();
	const { field } = useController({ control, name });

	return (
		<div>
			<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex">
				{TYPE_REQUEST_OPTIONS.map((type) => {
					return (
						<li
							key={type.value}
							className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r"
						>
							<div className="flex items-center pl-3">
								<label className="w-full flex items-center gap-2 py-3 my-0 text-sm font-medium text-gray-900 ">
									<input
										onChange={() => field.onChange(type.value)}
										type="radio"
										value={field.value}
										name="list-radio"
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
									/>
									{intl.formatMessage({ id: type.labelId })}
								</label>
							</div>
						</li>
					);
				})}
			</ul>
			{errors && (
				<div className="text-red-600 text-sm mt-1.5 font-light">
					<ErrorMessage name={name} errors={errors} />
				</div>
			)}
		</div>
	);
}

export default function VehicleRequestInfo({ data, className }) {
	const { allCountry, allProvinceEs } = useStaticQuery(
		graphql`
      query {
        allCountry {
          edges {
            node {
              id
              name
              alpha2Code
              translations {
                es
                en
                ca
                fr
                pt
              }
            }
          }
        }
        allProvinceEs {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
	);

	const countriesData = allCountry.edges.map(({ node }) => ({ ...node }));
	const provincesData = allProvinceEs.edges.map(({ node }) => ({ ...node }));

	const { matricula, marca, modelo, tipo_de_vehiculo, seo_name } = data;

	const recaptchaRef = useRef();
	const [fetching, setFetching] = useState(false);
	const intl = useIntl();

	const countries = useMemo(() => {
		const countries = [
			...getCountriesByLanguage(intl.locale, countriesData).filter(
				(item) => item.key === "ES",
			),
			...getCountriesByLanguage(intl.locale, countriesData).filter(
				(item) => item.key !== "ES",
			),
		];
		return countries;
	}, [intl, countriesData]);

	const schema = yup.object({
		request_type: yup
			.string()
			.required(intl.formatMessage({ id: "validation_required" })),
		full_name: yup
			.string()
			.required(intl.formatMessage({ id: "validation_required" })),
		email: yup
			.string()
			.email(intl.formatMessage({ id: "validation_email" }))
			.required(intl.formatMessage({ id: "validation_required" })),
		country: yup
			.string()
			.required(intl.formatMessage({ id: "validation_required" })),
		province: yup
			.string()
			.required(intl.formatMessage({ id: "validation_required" })),
		interested_type: yup
			.array()
			.min(1, intl.formatMessage({ id: "validation_required" })),
		accept_privacy: yup
			.bool()
			.required(intl.formatMessage({ id: "validation_conditions" }))
			.oneOf([true], intl.formatMessage({ id: "validation_conditions" })),
	});

	const acceptPrivacityLabel = intl.formatMessage(
		{ id: "misc_accept_privacy" },
		{
			misc_privacy: (
				<Link to="/politica-privacidad/">
					{intl.formatMessage({ id: "misc_privacy" })}
				</Link>
			),
			misc_legal: (
				<Link to="/politica-legal/">
					{intl.formatMessage({ id: "misc_legal" })}
				</Link>
			),
		},
	);

	const typesVehicles = categories.filter((item) => !item.external);

	const parsedTypeVehicles = typesVehicles.map((item) => {
		return {
			key: item.type,
			label: item.type,
		};
	});

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
		reset,
	} = useForm({
		resolver: yupResolver(schema),
		initialValues: {
			request_type: "",
			full_name: "",
			email: "",
			phone: "",
			country: "",
			province: "",
			accept_privacy: false,
			accept_newsletter: true,
		},
	});

	async function onSubmit(values) {
		setFetching(true);

		const finalValues = {
			...values,
			interested_type: values.interested_type || [],
			vehicle: {
				matricula,
				marca,
				modelo,
				seo_name,
			},
		};

		try {
			const recaptchaToken = await recaptchaRef.current.executeAsync();
			await post("/info_requests", {
				...finalValues,
				recaptchaToken,
			});
			toast("✅ Solicitud enviada correctamamente", {
				className: "text-sm",
			});
			reset();
		} catch (error) {
			console.error(error);
			toast("❌ Se produjo un error, vuelva a intentarlo", {
				className: "text-sm",
			});
		} finally {
			setFetching(false);
		}
	}

	const countryValue = watch("country");

	useEffect(() => {
		if (countryValue) {
			setValue("province", countryValue !== "ES" ? "OTRAS" : "");
		}
	}, [setValue, countryValue]);

	const countryValues = [
		{
			value: "",
			hidden: true,
			label: intl.formatMessage({ id: "misc_select_opcion" }),
		},
		...countries.map(({ key, label }) => ({
			value: key,
			label,
		})),
	];

	const provinceValues = useMemo(() => {
		return countryValue !== "ES"
			? [
					{
						value: "",
						hidden: true,
						label: intl.formatMessage({ id: "misc_select_opcion" }),
					},
					{ value: "OTRAS", label: "Otras" },
			  ]
			: [
					{
						value: "",
						hidden: true,
						label: intl.formatMessage({ id: "misc_select_opcion" }),
					},
					...provincesData.map(({ id, name }) => ({
						value: id,
						label: name,
					})),
			  ];
	}, [intl, provincesData, countryValue]);

	return (
		<div className={className}>
			<Element name="vehicleRequestInfo">
				<Card className="mt-[1rem]">
					<Card.Header>
						<b>
							<FormattedMessage id="misc_info_request.title" />
						</b>
					</Card.Header>
					<Card.Body>
						<form noValidate onSubmit={handleSubmit(onSubmit)}>
							<div className="mb-3">
								<div className="block text-gray-700 text-sm font-bold mb-2">
									<FormattedMessage id="misc_info_request.type" />
								</div>
								<TypeRequestRadio
									control={control}
									errors={errors}
									name="request_type"
								/>
							</div>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3">
								<InputTextForm
									isRequired
									id="full_name"
									label={intl.formatMessage({
										id: "misc_full_name",
									})}
									placeholder={intl.formatMessage({
										id: "misc_full_name",
									})}
									errors={errors}
									{...register("full_name")}
								/>
								<InputTextForm
									isRequired
									type="email"
									id="email"
									label={intl.formatMessage({
										id: "misc_email",
									})}
									placeholder={intl.formatMessage({
										id: "misc_email",
									})}
									errors={errors}
									{...register("email")}
								/>
								<InputSelectForm
									isRequired
									id="country"
									label={intl.formatMessage({ id: "misc_country" })}
									errors={errors}
									values={countryValues}
									{...register("country")}
								/>
								<InputSelectForm
									isRequired
									id="province"
									label="Provincia"
									errors={errors}
									values={provinceValues}
									{...register("province")}
								/>

								<InputTextForm
									type="tel"
									id="phone"
									label={intl.formatMessage({
										id: "misc_phone",
									})}
									placeholder={intl.formatMessage({
										id: "misc_phone",
									})}
									{...register("phone")}
								/>
								<InputMultiselectForm
									id="interested_type"
									control={control}
									defaultSelected={tipo_de_vehiculo ? [tipo_de_vehiculo] : []}
									name="interested_type"
									errors={errors}
									isRequired
									label={intl.formatMessage({ id: "misc_interested" })}
									options={parsedTypeVehicles}
									isInvalid={false}
								/>
							</div>
							<div className="grid grid-cols-1 mt-4 gap-3">
								<InputCheckbox
									{...register("accept_privacy")}
									id="accept_privacy"
									label={acceptPrivacityLabel}
									errors={errors}
								/>
								<InputCheckbox
									defaultChecked={true}
									{...register("accept_newsletter")}
									id="accept_newsletter"
									label={intl.formatMessage({
										id: "misc_accept_newsletter",
									})}
									errors={errors}
								/>
								<Recaptcha ref={recaptchaRef} />

								<Button color="trucksur" type="submit" isLoading={fetching}>
									<div className="flex w-fit mx-auto">
										<LuSend className="h-5 w-5" />
										<div className="ml-[5px]">
											<FormattedMessage id="misc_send" />
										</div>
									</div>
								</Button>
							</div>
						</form>
					</Card.Body>
				</Card>
			</Element>
		</div>
	);
}
