// Dependencies
import React, { useCallback, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import classNames from "classnames";
import {
	VideoModal,
	CarouselModal,
	ButtonFavoriteHeart,
	Button,
} from "@components";
import ImagesVehicleCarousel, {
	NextArrow,
	PrevArrow,
} from "@components/imagesVehicleCarousel";
import { calcMonthly } from "@lib/financial";
import { prettyNumber } from "@lib/utils";
import FavoritesContext from "@context/FavoritesContext";

function ButtonVideo({ data, ...props }) {
	return (
		<button
			type="button"
			className="absolute bottom-12 right-8 py-2 px-3 rounded-full text-white bg-opacity-80 bg-trucksur-700 z-[30] hover:bg-opacity-100 transition-all"
			{...props}
		>
			<div className="flex gap-2 items-center">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					className="w-7 h-7"
				>
					<title>Video</title>
					<path
						fillRule="evenodd"
						d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
						clipRule="evenodd"
					/>
				</svg>
				<span className="text-lg">Ver vídeo</span>
			</div>
		</button>
	);
}

export default function VehiclesCarousel({
	handleClickDatasheet,
	downloadingDatasheet,
	data,
	className,
	...props
}) {
	const intl = useIntl();
	const [isOpenVideo, setIsOpenVideo] = useState(false);
	const [isOpenImages, setIsOpenImages] = useState(false);
	const { vehicle, financial } = data;
	const isReserved = vehicle.estado === "Reservado";
	const isSold = vehicle.estado === "Vendido";
	const isBankable = vehicle.es_financiable;
	const interest = financial.interest;

	const monthlyFee = isBankable
		? calcMonthly({
				amount: vehicle.financ_pvp,
				entry: vehicle.financ_entrada,
				months: vehicle.financ_plazo,
				residualValue: vehicle.financ_valor_res,
				interest,
		  })
		: null;

	const images = vehicle?.fields?.images
		?.filter((item) => item)
		.map((img, index) => {
			return {
				alt: vehicle.seo_name,
				image: img,
				order: index + 1,
			};
		});

	const [initialIndexSlide, setInitialIndexSlide] = useState(0);
	const youtubeVideo = vehicle?.fields?.video_meta;

	const slickProps = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		dots: false,
	};

	function handleClickImage({ index }) {
		setInitialIndexSlide(index);
		setIsOpenImages(true);
	}

	const handleCloseCarousel = useCallback(() => {
		setIsOpenImages(false);
	}, [setIsOpenImages]);

	const handleCloseVideo = useCallback(() => {
		setIsOpenVideo(false);
	}, [setIsOpenVideo]);

	const { isFavorite, alternateFavorite } = useContext(FavoritesContext);

	return (
		<>
			<div
				className={classNames(
					"rounded-xl overflow-hidden relative mb-3 w-full max-w-[640px] mx-auto",
					className,
				)}
				{...props}
			>
				<div className="relative">
					{isReserved || isSold ? (
						<div className="absolute z-[20] top-[26px] -left-[42px]">
							<div className="rotate-[-45deg] bg-trucksur-600 text-white font-bold py-1 w-[170px] text-center">
								{intl.formatMessage({ id: "misc_sold" }).toUpperCase()}
							</div>
						</div>
					) : null}
					{youtubeVideo && (
						<ButtonVideo
							data={youtubeVideo}
							onClick={() => setIsOpenVideo(true)}
						/>
					)}
					{isBankable && (
						<div className="flex justify-between absolute w-full bg-green-700 bg-opacity-80 text-white z-[20] bottom-0 text-md py-1 px-2">
							<div className="font-medium">Financiable</div>
							<div className="font-light">
								{prettyNumber(
									monthlyFee,
									0,
									intl.formatMessage({ id: "financing.euro_month" }),
								)}
							</div>
						</div>
					)}
					<ButtonFavoriteHeart
						className="h-10 w-10 md:w-12 md:h-12 right-[20px] top-[20px]"
						id={data.vehicle.id}
					/>
					<ImagesVehicleCarousel
						images={images}
						slickProps={slickProps}
						handleClick={handleClickImage}
						isBankable={isBankable}
						className="cursor-zoom-in"
					/>
				</div>

				<div className="grid grid-cols-2">
					<button
						onClick={() => alternateFavorite(vehicle.id)}
						type="button"
						className="py-2 btn rounded-none bg-red-600 text-white font-medium text-sm md:text-md active:ring-0 focus:ring-0"
					>
						{!isFavorite(vehicle.id)
							? intl.formatMessage({ id: "vehicle.add_favorito" })
							: intl.formatMessage({ id: "vehicle.remove_favorito" })}
					</button>
					<Button
						aria-label={intl.formatMessage({ id: "vehicle.descarga_ficha" })}
						onClick={handleClickDatasheet}
						className="py-2 !px-3 w-full btn !rounded-none bg-trucksur-600 hover:bg-trucksur-600  text-white font-medium text-sm md:text-md active:ring-0 focus:ring-0"
						isLoading={downloadingDatasheet}
					>
						<div className="flex justify-center items-center gap-2">
							{!downloadingDatasheet ? (
								<svg
									stroke="currentColor"
									fill="currentColor"
									strokeWidth="0"
									viewBox="0 0 384 512"
									className="w-4 h-4"
									xmlns="http://www.w3.org/2000/svg"
								>
									<title>
										{intl.formatMessage({ id: "vehicle.descarga_ficha" })}
									</title>
									<path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z" />
								</svg>
							) : null}
							<span>
								<FormattedMessage id="vehicle.descarga_ficha" />
							</span>
						</div>
					</Button>
				</div>
			</div>

			<VideoModal
				isOpen={isOpenVideo}
				handleClose={handleCloseVideo}
				data={youtubeVideo}
			/>
			<CarouselModal
				isOpen={isOpenImages}
				handleClose={handleCloseCarousel}
				data={images}
				initialIndexSlide={initialIndexSlide}
			/>
		</>
	);
}
